import React, { useMemo, useState } from "react";
import { Flex, IconTypes, StyledText, Types, useToast } from "@emonvia/web-components";
import { translateError, useTranslation } from "@/utils/translations/Translator";
import Accordion from "@/components/Accordian/Accordian";
import ChargePointSection from "@steps/InstallationReport/components/ChargePointSection";
import SuccessFrame from "@/components/SuccessFrame";
import InfoFrame from "@/components/InfoFrame";
import { StyledBadge, StyledDivider, StyledIconButton, StyledOptionsContainer } from "./styles";
import { Button } from "@/components/TrackedComponents/reevComponents";
import ConnectorsSection from "@steps/InstallationReport/components/ConnectorsSection";
import { useEmailValidation } from "@/hooks/useEmailValidation";
import ReevApiCalls from "@/api/ReevApiCalls";
import Storage from "@/utils/Storage";
import {
  ABL_SERIAL_NUMBER,
  CPO_ONBOARDING_ID,
  GROUP_LIMIT,
  ONBOARDING_START_TIME
} from "@/utils/constants";
import { usePostHog } from "@/hooks/usePostHog";
import { restartWizard } from "@/routing/AllRoutes";
import { mediaDownload } from "@/utils/helpers";
import { useOnboarding } from "@/hooks/useOnboarding";
import ConsentCheckboxes from "@steps/InstallationReport/components/ConsentCheckboxes";
import { useQueryParams } from "@/hooks/useQueryParams";
import ValidatedTextInput from "@/components/ValidatedTextInput";

const InstallationReport: React.FC = () => {
  const { completeOnboarding, data } = useOnboarding();
  const { t } = useTranslation();
  const { notifySuccess, notifyError } = useToast();
  const { reset } = usePostHog();
  const { removeQueryParam } = useQueryParams();

  const [isLoading, setIsLoading] = useState(false);
  const [wasReportDownloaded, setWasReportDownloaded] = useState(false);
  const [agreeToPrivacyPolicy, setAgreeToPrivacyPolicy] = useState(false);
  const [agreeToNewsletter, setAgreeToNewsletter] = useState(false);
  const onboardingId = Storage.get(CPO_ONBOARDING_ID);

  const {
    email: electricianEmail,
    isValid: isElectricianEmailValid,
    handleChange: handleElectricianChange,
    emailErrorMessage: electricianErrorMessage
  } = useEmailValidation();

  const {
    email: customerEmail,
    isValid: isCustomerEmailValid,
    handleChange: handleCustomerChange,
    emailErrorMessage: customerErrorMessage
  } = useEmailValidation();

  const resetPosthogAndClearCacheHandler = () => {
    reset();
    Storage.remove(CPO_ONBOARDING_ID);
    Storage.remove(ONBOARDING_START_TIME);
    removeQueryParam(ABL_SERIAL_NUMBER);
    removeQueryParam(GROUP_LIMIT);
  };

  const downloadReportHandler = async () => {
    try {
      const pdfContent = await ReevApiCalls.createPdf(
        { pathParams: { onboardingId } },
        { responseType: "blob" }
      );

      mediaDownload("OnboardingComplete.pdf", pdfContent, "application/pdf");
      setWasReportDownloaded(true);
      notifySuccess(t("DOWNLOAD_SUCCESSFUL"));
    } catch (e) {
      notifyError(translateError(e));
    }
  };

  const FinishOnboarding = async () => {
    const { activationCode, id: chargePointId } = data?.chargePoint || {};
    const activationCodeWithoutDot = activationCode ? activationCode.replace(".", "") : null;
    try {
      setIsLoading(true);
      await completeOnboarding({
        onboardingId,
        chargePointId,
        customerEmail,
        electricianEmail,
        activationCode: activationCodeWithoutDot,
        receiveNewsletter: agreeToNewsletter
      });
      resetPosthogAndClearCacheHandler();
      setTimeout(() => {
        restartWizard();
      }, 2000);
    } catch {
      setIsLoading(false);
    }
  };

  const isFinishButtonDisabled = useMemo(() => {
    const hasValidElectricianEmail =
      isElectricianEmailValid === true && electricianEmail.trim() !== "";

    const hasValidCustomerEmail =
      customerEmail.trim() === "" || (isCustomerEmailValid === true && customerEmail.trim() !== "");

    return (
      !hasValidElectricianEmail || !agreeToPrivacyPolicy || !hasValidCustomerEmail || isLoading
    );
  }, [
    isLoading,
    isElectricianEmailValid,
    electricianEmail,
    agreeToPrivacyPolicy,
    customerEmail,
    isCustomerEmailValid
  ]);
  return (
    <Flex flexDirection="column" gap={16} justifyContent="center">
      <SuccessFrame description={t("CONFIGURATION_COMPLETED")} />
      <InfoFrame
        description={<StyledText type={Types.ns_16_l} text={t("HAND_OVER_USER_PACK")} />}
      />
      <Accordion title={t("CHARGE_POINT_DETAILS_FOR_RECORDS")}>
        <ChargePointSection />
      </Accordion>
      <Accordion title={t("CONNECTORS_DETAILS_FOR_RECORDS")}>
        <ConnectorsSection />
      </Accordion>
      <StyledOptionsContainer gap={16} flexDirection="column">
        <StyledText
          data-testid="InstallationReport_EnterYourEmail"
          type={Types.ns_16_m}
          text={t("ENTER_YOUR_EMAIL_TO_RECEIVE_REPORT")}
        />
        <Flex data-testid="InstallationReport_ReportContainer" flexDirection="column" gap={16}>
          <Flex data-testid="InstallationReport_Email" flexDirection="column">
            <StyledText type={Types.ns_16_b} text={t("EMAIL")} margin={[0, 0, 8, 0]} />
            <ValidatedTextInput
              data-testid="TextInput_ENTER_YOUR_EMAIL"
              placeholder={t("ENTER_YOUR_EMAIL")}
              margin={[0, 0, 12, 0]}
              value={electricianEmail}
              valid={isElectricianEmailValid}
              error={isElectricianEmailValid === false}
              errorMessage={electricianErrorMessage && t(electricianErrorMessage)}
              onChange={handleElectricianChange}
              validators={["removeSpaces", { key: "limit", options: { limit: 50 } }]}
            />
            <ConsentCheckboxes
              agreeToPrivacyPolicy={agreeToPrivacyPolicy}
              agreeToNewsletter={agreeToNewsletter}
              setAgreeToPrivacyPolicy={setAgreeToPrivacyPolicy}
              setAgreeToNewsletter={setAgreeToNewsletter}
            />
          </Flex>
          <StyledDivider />
          <Flex data-testid="InstallationReport_EmailToCustomer" flexDirection="column">
            <Flex gap={8} alignItems="center" margin={[0, 0, 8, 0]}>
              <StyledText type={Types.ns_16_b} text={t("EMAIL_TO_CUSTOMER")} />
              <StyledBadge size={12} type={Types.ns_14_m} text={t("OUR_SUGGESTION")} />
            </Flex>
            <StyledText
              type={Types.ns_14_m}
              text={t("ENTER_CUSTOMER_EMAIL_REPORT")}
              margin={[0, 0, 16, 0]}
            />
            <ValidatedTextInput
              data-testid="TextInput_ENTER_CUSTOMER_EMAIL"
              placeholder={t("ENTER_CUSTOMER_EMAIL")}
              value={customerEmail}
              valid={isCustomerEmailValid}
              error={isCustomerEmailValid === false}
              errorMessage={customerErrorMessage && t(customerErrorMessage)}
              onChange={handleCustomerChange}
              validators={["removeSpaces", { key: "limit", options: { limit: 50 } }]}
            />
          </Flex>
          <StyledDivider />
          <Flex data-testid="InstallationReport_DownloadPdf" gap={8} alignItems="center">
            <Flex flexDirection="column" style={{ maxWidth: "calc(100% - 52px)" }}>
              <StyledText type={Types.ns_16_b} text={t("PDF_DOWNLOAD")} margin={[0, 0, 8, 0]} />
              <StyledText
                type={Types.ns_14_m}
                text={t("THE_REPORT_WILL_BE_SAVED")}
                margin={[0, 0, 16, 0]}
              />
            </Flex>
            <StyledIconButton
              data-testid="DownloadPdfCheckbox"
              look="secondary"
              icon={IconTypes.pdf}
              iconSize={30}
              shape="square"
              width="44px"
              clicked={wasReportDownloaded}
              onClick={downloadReportHandler}
            />
          </Flex>
        </Flex>
      </StyledOptionsContainer>
      <Button
        data-testid={"ReportButton"}
        label={t("FINISH")}
        useNunitoFont
        width="100%"
        margin={[16, 0, 0, 0]}
        disabled={isFinishButtonDisabled}
        onClick={FinishOnboarding}
      />
    </Flex>
  );
};

export default InstallationReport;
