import { createContext, FC, PropsWithChildren, useEffect } from "react";
import { useState } from "react";
import {
  ICompleteOnboardingRequest,
  IOnboardingResponse,
  IUpdateOnboardingRequest
} from "@/api/ApiTypes";
import ReevApiCalls from "@/api/ReevApiCalls";
import { useToast } from "@emonvia/web-components";
import { translateError } from "@/utils/translations/Translator";
import i18next from "i18next";
import { Language } from "@/utils/enums/Language";
import Storage from "@/utils/Storage";
import { CPO_ONBOARDING_ID } from "@/utils/constants";
import { usePostHog } from "@/hooks/usePostHog";
import { useNavigate } from "react-router-dom";
import allRoutes, {
  AllRoutes,
  goToNextStep,
  NEXT_STEP_TO_ONBOARDING_STEP,
  Path
} from "@/routing/AllRoutes";
import i18n from "@/utils/i18n";
import { useLocation } from "react-router";

export interface IOnboardingReturn {
  start: () => Promise<IOnboardingResponse>;
  get: () => Promise<IOnboardingResponse>;
  update: (payload: IUpdateOnboardingRequest) => Promise<IOnboardingResponse>;
  goNextWithUpdate: () => Promise<void>;
  data: IOnboardingResponse;
  isGetLoading: boolean;
  isStartLoading: boolean;
  isUpdateLoading: boolean;
  isLoading: boolean;
  completeOnboarding: (payload: ICompleteOnboardingRequest) => Promise<void>;
}

const OnboardingContext = createContext<IOnboardingReturn>({
  data: null,
  isGetLoading: false,
  isStartLoading: false,
  isUpdateLoading: false,
  isLoading: false,
  goNextWithUpdate: () => Promise.resolve(),
  get(): Promise<IOnboardingResponse> {
    return Promise.resolve(null);
  },
  update(): Promise<IOnboardingResponse> {
    return Promise.resolve(null);
  },
  start: () => null,
  completeOnboarding: () => Promise.resolve()
});

export const OnboardingProvider: FC<PropsWithChildren> = ({ children }) => {
  const { notifyError } = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const { identify } = usePostHog();
  const [onboardingData, setOnboardingData] = useState<IOnboardingResponse>();
  const [isGetLoading, setIsGetLoading] = useState<boolean>(false);
  const [isUpdateLoading, setIsUpdateLoading] = useState<boolean>(false);
  const [isStartLoading, setIsStartLoading] = useState<boolean>(false);

  const getOnboardingHandler = async () => {
    try {
      const id = Storage.get(CPO_ONBOARDING_ID);
      if (!id) {
        navigate(AllRoutes.CONFIG_WIZARD_ROOT);
        return;
      }

      setIsGetLoading(true);

      const { data } = await ReevApiCalls.getOnboardingById({
        pathParams: { onboardingId: id }
      });

      setOnboardingData(data);
      i18n.changeLanguage(data.language.toLocaleLowerCase());
      identify();

      return data;
    } catch (e) {
      notifyError(translateError(e));
      throw e;
    } finally {
      setIsGetLoading(false);
    }
  };

  const updateOnboardingHandler = async (payload: IUpdateOnboardingRequest) => {
    try {
      const id = Storage.get(CPO_ONBOARDING_ID);
      if (!id) return;

      setIsUpdateLoading(true);

      const { data } = await ReevApiCalls.updateOnboarding({
        payload: { ...payload, language: i18next.language?.toUpperCase() as Language },
        pathParams: { onboardingId: id }
      });

      setOnboardingData(data);
      identify();

      return data;
    } catch (e) {
      notifyError(translateError(e));
      throw e;
    } finally {
      setIsUpdateLoading(false);
    }
  };

  const startOnboardingHandler = async () => {
    try {
      setIsStartLoading(true);
      const { data } = await ReevApiCalls.createOnboarding({
        payload: {
          language: i18next.language?.toUpperCase() as Language
        }
      });

      setOnboardingData(data);

      Storage.set(CPO_ONBOARDING_ID, data.id);
      identify();

      return data;
    } catch (e) {
      notifyError(translateError(e));
      throw e;
    } finally {
      setIsStartLoading(false);
    }
  };

  const goNextWithUpdateHandler = async () => {
    const currentPath = location.pathname;
    if (NEXT_STEP_TO_ONBOARDING_STEP[currentPath as Path])
      await updateOnboardingHandler({ state: NEXT_STEP_TO_ONBOARDING_STEP[currentPath as Path] });

    await goToNextStep();
  };

  const navigateWithQuery = (path: Path | string) => {
    const queryParams = location.search;
    if (queryParams) {
      navigate(path + queryParams);
    } else {
      navigate(path);
    }
  };

  useEffect(() => {
    const handleNavigation = async () => {
      try {
        const onboarding = await getOnboardingHandler();

        if (!onboarding || !onboarding.state) {
          navigateWithQuery(allRoutes.CONFIG_WIZARD_ROOT);
          return;
        }

        const currentPath = location.pathname;

        if (currentPath === onboarding.state) {
          return;
        }

        if (onboarding.state) {
          navigateWithQuery(onboarding.state);
        }
      } catch {
        navigateWithQuery(allRoutes.CONFIG_WIZARD_ROOT);
      }
    };

    const timer = setTimeout(() => {
      handleNavigation();
    }, 100);
    return () => clearTimeout(timer);
  }, [location.search]);

  const completeOnboarding = async (payload: ICompleteOnboardingRequest) => {
    try {
      await ReevApiCalls.completeOnboarding({
        payload
      });
    } catch (e) {
      notifyError(translateError(e));
      throw e;
    }
  };

  return (
    <OnboardingContext.Provider
      value={{
        start: startOnboardingHandler,
        get: getOnboardingHandler,
        update: updateOnboardingHandler,
        isLoading: isGetLoading || isStartLoading || isUpdateLoading,
        isGetLoading,
        isStartLoading,
        isUpdateLoading,
        data: onboardingData,
        goNextWithUpdate: goNextWithUpdateHandler,
        completeOnboarding
      }}
    >
      {children}
    </OnboardingContext.Provider>
  );
};

export default OnboardingContext;
