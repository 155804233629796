import React, { useEffect, useState } from "react";
import { Flex, Icon, IconTypes, isNotEmpty } from "@emonvia/web-components";
import radioOn from "@/assets/images/checkbox-checked.svg";
import radioOff from "@/assets/images/checkbox-unchecked.svg";
import TooltipWrapper from "@/components/Tooltip/Tooltip";

interface IProps {
  dataTestid?: string;
  initialValue?: boolean;
  description: React.ReactNode | string;
  onChange: (val: boolean) => void;
  margin?: Array<string | number>;
  disabled?: boolean;
  tooltipText?: string | React.ReactElement;
}

const CheckBox: React.FC<IProps> = ({
  dataTestid,
  initialValue = false,
  description,
  onChange,
  margin = [],
  disabled,
  tooltipText,
  ...rest
}) => {
  const [checked, setChecked] = useState<boolean>(initialValue);

  useEffect(() => {
    setChecked(initialValue);
  }, [initialValue]);

  const handleCheckboxClick = () => {
    if (disabled) return;
    setChecked((prevChecked: boolean) => {
      onChange(!prevChecked);
      return !prevChecked;
    });
  };

  return (
    <Flex
      flexDirection="row"
      alignItems="flex-start"
      margin={isNotEmpty(margin) ? [...margin] : []}
      data-testid={dataTestid}
    >
      <Flex
        style={{
          cursor: "pointer"
        }}
        onClick={handleCheckboxClick}
        data-testid={dataTestid}
      >
        <img
          key={dataTestid}
          src={checked && !disabled ? radioOn : radioOff}
          width={16}
          height={16}
          style={{
            marginTop: 2
          }}
          {...rest}
          alt={"Checkbox"}
        />
      </Flex>
      <Flex margin={[0, 0, 0, 8]} flex={1} flexDirection="column">
        <Flex flexDirection="row" justifyContent="space-between">
          {description}
          {tooltipText && (
            <Flex margin={[0, 0, 0, 8]} style={{ display: "grid", height: 0 }}>
              <TooltipWrapper content={tooltipText}>
                <Icon size={18} color="grey500" type={IconTypes.infoCircle} />
              </TooltipWrapper>
            </Flex>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default CheckBox;
