import React from "react";
import { Flex, StyledText, Types } from "@emonvia/web-components";
import Checkbox from "@/components/Checkbox/Checkbox";
import { Trans } from "react-i18next";
import { PRIVACY_POLICY_BY_LANGUAGE } from "@/utils/constants";
import { getCurrentLanguage } from "@/utils/i18n";
import { useTranslation } from "@/utils/translations/Translator";

type IProps = {
  agreeToPrivacyPolicy: boolean;
  agreeToNewsletter: boolean;
  setAgreeToPrivacyPolicy: (val: boolean) => void;
  setAgreeToNewsletter: (val: boolean) => void;
};

const ConsentCheckboxes: React.FC<IProps> = ({
  agreeToPrivacyPolicy,
  setAgreeToPrivacyPolicy,
  agreeToNewsletter,
  setAgreeToNewsletter
}) => {
  const { t } = useTranslation();

  return (
    <Flex flexDirection="column" justifyContent="center" gap={16}>
      <Checkbox
        data-testid="I_WANT_STAY_UPTO_DATE_ON_PRODUCT_LAUNCH"
        description={
          <StyledText
            type={Types.ns_14_m}
            text={t("I_WANT_STAY_UPTO_DATE_ON_PRODUCT_INFORMATION")}
          />
        }
        initialValue={agreeToNewsletter}
        onChange={setAgreeToNewsletter}
        tooltipText={
          <Trans
            i18nKey={"I_CAN_UNSUBSCRIBE_AT_INFO"}
            components={{
              a: (
                <a
                  key="PRIVACY_POLICY"
                  style={{ color: "white" }}
                  href={PRIVACY_POLICY_BY_LANGUAGE[getCurrentLanguage()]}
                  target="_blank"
                  rel="noopener noreferrer"
                />
              )
            }}
          />
        }
      />
      <Checkbox
        data-testid="I_CONFIRM_I_HAVE_READ_PRIVACY_POLICY"
        description={
          <StyledText
            type={Types.ns_14_m}
            text={
              <Trans
                i18nKey="I_CONFIRM_I_HAVE_READ_PRIVACY_POLICY"
                components={{
                  a: (
                    <a
                      key="PRIVACY_POLICY"
                      href={PRIVACY_POLICY_BY_LANGUAGE[getCurrentLanguage()]}
                      target="_blank"
                      rel="noopener noreferrer"
                    />
                  )
                }}
              />
            }
          />
        }
        initialValue={agreeToPrivacyPolicy}
        onChange={setAgreeToPrivacyPolicy}
        tooltipText={t("WE_PROCESS_YOUR_EMAIL_ADDRESS")}
      />
    </Flex>
  );
};

export default ConsentCheckboxes;
