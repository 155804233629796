import { useState, useCallback } from "react";
import { EMAIL_REGEXP } from "@/utils/regexp";
import { TransKey } from "@/utils/helpers";

export const useEmailValidation = (initialValue: string = "") => {
  const [email, setEmail] = useState<string>(initialValue ?? "");
  const [isValid, setIsValid] = useState<boolean>(null);
  const [emailErrorMessage, setEmailErrorMessage] = useState<TransKey | "">("");

  const validateEmail = useCallback((value: string) => {
    if (!value) {
      setIsValid(null);
      setEmailErrorMessage("");
      return;
    }

    const isValidEmail: boolean = EMAIL_REGEXP.test(value);
    setIsValid(isValidEmail);
    setEmailErrorMessage(isValidEmail ? "" : "INVALID_EMAIL");
  }, []);

  const handleChange = useCallback(
    (value: string) => {
      setEmail(value);
      validateEmail(value);
    },
    [validateEmail]
  );

  return {
    email,
    isValid,
    emailErrorMessage,
    handleChange
  };
};
