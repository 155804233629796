import queryString from "query-string";
import { ajaxService, IInvokeOptions } from "src/api/AjaxService";
import {
  IOnboardingResponseData,
  IUpdateOnboardingRequest,
  ICompleteOnboardingRequest,
  ICreateOnboardingRequest,
  IEvseIdResponse,
  IGetChargePointResponseData
} from "./ApiTypes";

export default class ReevApiCalls {
  public static getOnboardingById(
    request: { pathParams: { onboardingId: string } },
    options?: IInvokeOptions
  ) {
    return ajaxService.get<IOnboardingResponseData>(
      `/onboardings/${request.pathParams.onboardingId}`,
      options
    );
  }

  public static updateOnboarding(
    request: { pathParams: { onboardingId: string }; payload: IUpdateOnboardingRequest },
    options?: IInvokeOptions
  ) {
    return ajaxService.put<IOnboardingResponseData>(
      `/onboardings/${request.pathParams.onboardingId}`,
      request.payload,
      options
    );
  }

  public static completeOnboarding(
    request: { payload: ICompleteOnboardingRequest },
    options?: IInvokeOptions
  ) {
    return ajaxService.post(`/operations/complete-onboarding`, request.payload, options);
  }

  public static createOnboarding(
    request: { payload: ICreateOnboardingRequest },
    options?: IInvokeOptions
  ) {
    return ajaxService.post<IOnboardingResponseData>(`/onboardings`, request.payload, options);
  }

  public static createPdf(
    request: { pathParams: { onboardingId: string } },
    options?: IInvokeOptions
  ) {
    return ajaxService.get<string>(`/operations/pdf/${request.pathParams.onboardingId}`, options);
  }

  public static verifyActivationCode(
    request: { pathParams: { activationCode: string } },
    options?: IInvokeOptions
  ) {
    return ajaxService.get(
      `/operations/activation-code/${request.pathParams.activationCode}/verify`,
      options
    );
  }

  public static getEvseByInfoById(
    request: { pathParams: { evseId: string } },
    options?: IInvokeOptions
  ) {
    return ajaxService.get<IEvseIdResponse>(`/evse/${request.pathParams.evseId}`, options);
  }

  public static getChargePointBySerialNumber(
    request: { pathParams: { serialNumber: string } },
    options?: IInvokeOptions
  ) {
    return ajaxService.get<IGetChargePointResponseData>(
      `/charge-points/${request.pathParams.serialNumber}`,
      options
    );
  }
}

export function buildQueryString(queryParams?: Record<string, unknown>, post?: string) {
  return `${
    !!queryParams
      ? `${queryString.stringify(queryParams, { skipNull: true })}${!!post ? "&" + post : ""}`
      : ""
  }`;
}
