import { useTranslation } from "@/utils/translations/Translator";
import { Flex, List, Types } from "@emonvia/web-components";
import InfoFrame from "@/components/InfoFrame";
import ErrorFrame from "@/components/ErrorFrame";
import infoErrorIcon from "@/assets/images/infoErrorIcon.svg";
import { Trans } from "react-i18next";
import { StyledBlueLinkForTrans } from "@/components/TrackedComponents/reevComponents";
import { goToNextStep } from "@/routing/AllRoutes";
import { Button } from "@/components/TrackedComponents/reevComponents";
import { useSerialNumber } from "@/hooks/useSerialNumber";
import { LATEST_FIRMWARE_URL_BY_LANGUAGE } from "@/utils/constants";
import { getCurrentLanguage } from "@/utils/i18n";

const ConnectivityOffline = () => {
  const { t } = useTranslation();
  const { serialNumber } = useSerialNumber();

  return (
    <Flex flexDirection="column" justifyContent="center" gap={16}>
      <ErrorFrame
        icon={infoErrorIcon}
        title={t("OFFLINE_POINT_FOUND")}
        subtitle={serialNumber}
        description={t("WE_FOUND_OFFLINE_POINT_IN_SYSTEM")}
      />
      <InfoFrame
        textType={Types.ns_16_m}
        description={
          <Flex gap={16} flexDirection="column">
            <span>{t("POINT_ON_OLD_FIRMWARE")}</span>
            <List>
              <li>
                <Trans
                  i18nKey="INSTALL_LATEST_FIRMWARE"
                  components={[
                    <StyledBlueLinkForTrans
                      key="LATEST_FIRMWARE"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        window.open(LATEST_FIRMWARE_URL_BY_LANGUAGE[getCurrentLanguage()])
                      }
                      target="_blank"
                    />
                  ]}
                />
              </li>
              <li>{t("GO_AFTER_FIRMWARE_UPDATE")}</li>
            </List>
          </Flex>
        }
      />
      <Button
        label={t("CONTINUE")}
        useNunitoFont
        width="100%"
        margin={[16, 0, 0, 0]}
        onClick={goToNextStep}
      />
    </Flex>
  );
};

export default ConnectivityOffline;
