import { useTranslation } from "@/utils/translations/Translator";
import {
  Button,
  Flex,
  isNullOrUndefined,
  RenderIf,
  StyledText,
  Types
} from "@emonvia/web-components";
import theme from "@/utils/theme/theme";
import Storage from "@/utils/Storage";
import { StyledList, StyledUserConsentModal } from "./styles";
import ImprovementImage from "@/assets/images/improvement.svg";
import { Trans } from "react-i18next";
import {
  ACCEPTED,
  AGREED_TO_ANALYTICS_CONSENT,
  PRIVACY_POLICY_BY_LANGUAGE,
  REJECTED
} from "@/utils/constants";
import React from "react";
import { getCurrentLanguage } from "@/utils/i18n";

type IProps = { posthogAccepted: boolean; submitPreference: (val: string) => void };
const UserConsentModal: React.FC<IProps> = ({ posthogAccepted, submitPreference }) => {
  const { t } = useTranslation();

  return (
    <RenderIf
      renderContent={
        !posthogAccepted && isNullOrUndefined(Storage.get(AGREED_TO_ANALYTICS_CONSENT))
      }
    >
      <StyledUserConsentModal
        actions={
          <>
            <Button
              data-testid="I_CONSENT"
              label={t("I_CONSENT")}
              withoutShadow
              useNunitoFont
              onClick={() => submitPreference(ACCEPTED)}
            />
            <Button
              data-testid="I_DO_NOT_CONSENT"
              label={t("I_DO_NOT_CONSENT")}
              look="secondary"
              withoutShadow
              useNunitoFont
              onClick={() => submitPreference(REJECTED)}
            />
          </>
        }
        closable={false}
      >
        <Flex justifyContent="center" margin={[0, 0, 24]}>
          <img src={ImprovementImage} width={80} height={82} />
        </Flex>
        <Flex flexDirection="column" gap={8}>
          <StyledText
            type={Types.ns_18_sb}
            text={t("HELP_US_IMPROVE_PRODUCT")}
            color={theme.colors.primaryBlack}
          />
          <StyledText
            type={Types.ns_16_l}
            text={<Trans i18nKey="STRIVE_TO_IMPROVE_OUR_SERVICE" />}
            color={theme.colors.grey750}
          />
          <StyledList>
            <li>
              <StyledText
                type={Types.ns_16_l}
                text={t("IP_ADDRESS")}
                color={theme.colors.grey750}
              />
            </li>
            <li>
              <StyledText type={Types.ns_16_l} text={t("TIMESTAMP")} color={theme.colors.grey750} />
            </li>
            <li>
              <StyledText
                type={Types.ns_16_l}
                text={t("BROWSER_VERSION")}
                color={theme.colors.grey750}
              />
            </li>
            <li>
              <StyledText
                type={Types.ns_16_l}
                text={t("SCREEN_RESOLUTION")}
                color={theme.colors.grey750}
              />
            </li>
            <li>
              <StyledText type={Types.ns_16_l} text={t("DEVICE_OS")} color={theme.colors.grey750} />
            </li>
            <li>
              <StyledText
                type={Types.ns_16_l}
                text={t("REFERRER_URL")}
                color={theme.colors.grey750}
              />
            </li>
          </StyledList>
          <StyledText
            type={Types.ns_16_l}
            text={t("AGREE_IF_YOU_SUPPORT_US")}
            color={theme.colors.grey750}
          />
          <StyledText
            type={Types.ns_16_l}
            text={
              <Trans
                i18nKey="INFORMATION_ABOUT_PRIVACY_POLICY"
                components={[
                  <a
                    key="PRIVACY_POLICY"
                    role="link"
                    style={{ cursor: "pointer" }}
                    onClick={() => window.open(PRIVACY_POLICY_BY_LANGUAGE[getCurrentLanguage()])}
                    target="_blank"
                    rel="noreferrer"
                  />
                ]}
              />
            }
            color={theme.colors.grey750}
          />
        </Flex>
      </StyledUserConsentModal>
    </RenderIf>
  );
};

export default UserConsentModal;
